import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"

const KripaDhara = () => {
   return( 
       <>
       <Header />
       <Breadcrumb pageTitle="Kripa Dhara" mid="Coummunities" breadcrumbName="Kripa Dhara" />
       <div className="innerpage">
           <div className="container">
               <div className="row">
                   <div className="col-md-12"> 
                    <h2 className="title">Agra Region </h2>
                     <h3 className="sub_title">Kripa Dhara</h3>
                     <p><b>List of Communities</b></p>
                     <ul className="communities">
                                <li>  Aachen  </li>
                                <li>  Agra </li>
                                <li>  Aluva	 </li>
                                <li>  Amausi </li>
                                <li>  Bhiwadi </li>
                                <li>  Dakpathar </li>
                                <li>  Dehradun </li>
                                <li>  Dhampur </li>
                                <li>  Florence </li>
                                <li>  G. Noida </li>
                                <li>  Gulaothi </li>
                                <li>  Indirapuram </li>
                                <li>  Jahangirabad </li>
                                <li>  Josephnagar </li>
                                <li>  Khanaura </li>
                                <li>  Khatauli </li>
                                <li>  Lucknow  </li>
                                <li>  Magur mari </li>
                                <li>  Mandya </li>
                                <li>  Narnaul </li>
                                <li>  Noorpur (Assisi Bhawan) </li>
                                <li>  Noorpur (St. Mary’s) </li>
                                <li>  Panipat </li>
                                <li>  Shamli </li>
                                <li> Vazhathope </li>
                                <li>  Vikasnagar </li>
                     </ul>
                      
                 </div>
                 <div className="flex-v row">
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara1.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>St. Mary's convent, Dakpathar</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara2.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Our Lady of Graces Convent Amausi Lucknow</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara3.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Fslg Achen, Germany</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara4.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara4.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Our Lady Of Graces Convent Jahangirabad</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara5.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara5.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Maria Sadan Narnaul</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara6.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara6.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Assisi  Bhavan, Indirapuram</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara7.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara7.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Our Lady of grace _s Convent, Shamli</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara8.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara8.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Sacred heart convent Khanaura Punjab</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara9.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara9.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Our Lady of Grace Convent Magurmari Assam</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara10.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara10.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>St Mary's Convent, Vikasnagar</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara11.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara11.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>St. Francis Convent, Vazhathope, Kerala</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara12.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara12.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Assissi health center. Noorpur</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara13.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara13.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>St. Mary's Convent Aluva</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara14.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara14.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Premdham convent, Dehradun</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara15.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara15.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Florence from Sr. Jancy</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara16.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara16.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>Predham, Dehradun</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara17.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara17.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>St. Mary's convent, Noorpur</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara18.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara18.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>St. Mary's Convent, Gulaothi</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara19.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Kripadhara19.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                <h4>St. Francis Convent, Bilaspur</h4>
                            </a>
                        </div>
                     </div>
                   </div>
               </div> 
               
           </div>
       <Footer />
       </>
   )
}
export default KripaDhara
import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"

const Contact = () => {
   return( 
       <>
       <Header />
       <Breadcrumb pageTitle="Contact" mid="About" breadcrumbName="Contact" />
       <div class="innerpage">
           <div className="container">
               <div className="row">
                   <div className="col-md-12">
                   <h2 className="title"> Connect With Us </h2>
                   </div>
                   <div className="col-md-4">
                   <div class="contactinfo">
                                    <div class="addressbox">
                                         <div class="addressicn"><i class="bi bi-geo-alt-fill"></i></div> 
                                         <a href="">Franciscan Sisters of Our Lady Of Graces, Meerut, Uttar Pradesh-1713  </a>
                                    </div>
                                     <div class="addressbox">
                                        <div class="addressicn"><i class="bi bi-envelope-fill"></i></div> 
                                        <a href="mailto:contactfslg65@gmail.com">contactfslg65@gmail.com</a> <br />
                                        
                                    </div>
                                    <div class="addressbox">
                                        <div class="addressicn"><i class="bi bi-telephone-fill"></i></div> 
                                        <a href="tel:0121-2631780">0121-2631780 </a>, <a href="tel:0121-2630790"> 2630790</a>
                                    </div>
                               </div>
                               </div>
                   <div class="contactbox col-md-8">
                               
                               <div class="frame">
                                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3488.6327190376483!2d77.6777172!3d29.027861400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c65a543ada663%3A0xae4f64819874f9b!2sFSLG%20Generalate%20Kanker%20Khera!5e0!3m2!1sen!2sin!4v1681456066142!5m2!1sen!2sin" width="100%" height="450"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                               </div>
                               
                        </div>
                   </div>
               </div>
           </div>
                
       <Footer />
       </>
   )
}
export default Contact
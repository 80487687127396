// import logo from './logo.svg';
import './App.css';
import { BrowserRouter , Route,  Routes } from 'react-router-dom';

// import Home from './Pages/home'
import Home from './Pages/home'; 
import Founder from './Pages/founder'; 
import Administration from './Pages/administration'; 
import Contact from './Pages/contact'; 
import History from './Pages/history'; 
import Formation from './Pages/formation'; 
import EducationMinistry from './Pages/education-ministry'; 
import KripaDhara from './Pages/kripaDhara'; 
import ShantiDhara from './Pages/shantiDhara'; 
import FlowerInHeaven from './Pages/flowerInHeaven'; 
import Events from './Pages/events'; 
import News from './Pages/news'; 
import Gallery from './Pages/gallery'; 
import SubGallery from './Pages/subGallery'; 
import HealthMinistry from './Pages/healthministry'; 
import HealthSubGallery from './Pages/HealthsubGallery'; 
import PastoralMinistry from './Pages/pastoralministry'; 
import SocialGallery from './Pages/socialministry'; 
import SocialSubGallery from './Pages/socialsubGallery'; 
import SubPestoralMinistry from './Pages/subpestoral'; 
import VideoGallery from './Pages/videoGallery'; 
// import About from './Pages/about'; 
// import Vision from './Pages/vision'; 
// import Mission from './Pages/mission'; 
// import ContactUs from './Pages/contactUs'; 
// import SMC from './Pages/smc'; 

function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Home />} />  
            <Route path="/founder" element={<Founder />} />  
            <Route path="/administration" element={<Administration />} />  
            <Route path="/contact" element={<Contact />} />  
            <Route path="/history" element={<History />} />  
            <Route path="/formation" element={<Formation />} />  
            <Route path="/education-ministry" element={<EducationMinistry />} />  
            <Route path="/kripaDhara" element={<KripaDhara />} />  
            <Route path="/shantiDhara" element={<ShantiDhara />} />  
            <Route path="/flowerInHeaven" element={<FlowerInHeaven />} />  
            <Route path="/events" element={<Events />} />  
            <Route path="/news" element={<News />} />  
            <Route path="/gallery" element={<Gallery />} />  
            <Route path="/subGallery" element={<SubGallery />} />  
            <Route path="/healthministry" element={<HealthMinistry />} />  
            <Route path="/HealthSubGallery" element={<HealthSubGallery />} />  
            <Route path="/pastoralministry" element={<PastoralMinistry />} />  
            <Route path="/subpestoral" element={<SubPestoralMinistry />} />  
            <Route path="/socialministry" element={<SocialGallery />} />
            <Route path="/socialsubGallery" element={<SocialSubGallery />} />
            <Route path="/videoGallery" element={<VideoGallery />} />
          {/* <Route path="/vision" element={<Vision />} /> 
          <Route path="/mission" element={<Mission />} /> 
          <Route path="/contactUs" element={<ContactUs />} /> 
          <Route path="/smc" element={<SMC />} />  */}
        </Routes> 
      </BrowserRouter>
    </div>
  );
}

export default App;
import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"

const History = () => {
   return( 
       <>
       <Header />
       <Breadcrumb pageTitle="History"  breadcrumbName="History" />
       <div class="innerpage">
           <div className="container">
               <div className="row">
                   <div className="col-md-12">
                   <h2 className="title"> Birth Of Our Congregation</h2>
                   <div class="historybox">
                                <p>When Most Rev. Joseph Evangelisti, OFM Cap. was made the Bishop of the newly constituted Meerut Diocese, he felt the need for Women Religious in the field of evangelization. So, he made requests to different Congregations to collaborate with the Diocese in its pastoral and apostolic activities. Though there were Congregations severing the Diocese, Archbishop Evangelisti realized that he needed more persons for the spreading of the Lord’s Kingdom. Then, he began to think of founding a Religious Congregation for Women. He sought the help of the Lord in prayer. Repeated requests from Bishop Angelo Poli, OFM Cap., the retired Bishop of Allahabad, to accept seven Franciscan Tertiaries was something of a sign to go ahead with what was in his mind. He accepted the seven Sisters in <b>1962.</b></p>
                                <p>Keeping in mind the founding of a new Congregation, he continued praying for light and guidance from God. After much prayer and reflection, he began to work towards its realization. He sought the permission from the Ecclesiastical Authorities and on November<b> 27, 1963, </b> the Sacred Congregation of Propaganda Fide granted permission to Archbishop Evangelisti to erect a Religious Congregation for Women through a decree bearing Protocol No.<b> 2828/63.</b> Thus, Archbishop Evangelisti founded the Congregation of the Franciscan Sisters of Our Lady of Graces on June <b> 25, 1965. On June 29 </b> of the same year, he got the canonical deficiency of the seven Sisters ratified, whom he had accepted as members of the newly founded Congregation. These first members of the Congregation were Sisters Angela, Rosa, Cecilia, Anna, Josephine, Collette and Assunta. As said earlier, they were recommended by Most Rev. Angelo Poli, as he was worried about the future of these Sisters who was under his care in Allahabad. <b>On July 1, 1965, </b> a house at 23 Curzen Road, Dehra Dun was canonically erected as a Religious House and thus, it became the Mother House and first Novitiate of the Congregation. The Archbishop also got two Sisters, Sister Lucy and Sister Maris of the Mission Sisters of Ajmer to assist in the administration and in the formation of the new candidates who had arrived<b> on July 24, 1965.</b> Thus, the young Congregation came into being. The Founder appointed Sister Angela as the Acting Superior General. The first and second batch of candidates were received in Dehra Dun, and with the arrival of the third batch of candidates the entire formation was shifted to Raja - ka - Tajpur on <b> August 21, 1967</b> due to lack of space in Dehra Dun. </p>
                                <p> During the first decade of the infant Congregation, it had to go through a lot of struggles. The Sisters had to depend on the Founder for their needs and the needs of those under formation. The Archbishop met Group Captain Cheshire, the founder of Cheshire homes, and offered the service of his Sisters which the latter accepted. Mother Angela and the Sisters began to stay at the place offered by Group Captain Cheshire who was kind enough to build a Chapel for them. Later, in order to support the Congregation, Mother Angela established a Primary School in Dehra Dun. In addition, to obtain some income, the Sisters and those under formation prepared embroidery items and sold them during the feast of Our Lady of Graces at Sardhana. </p>
                                <p>The Founder was very much concerned about the spiritual growth of his daughters. So he kept guiding them and exhorting them to live a life of holiness through his circulars as well as by meeting every sister and those under formation individually. Even when he was in sick-bed, he wrote many circulars which were to be read in common now and then to ensure that Sisters and those under formation got the necessary guidance for their spiritual growth. </p>
                                <p>Before the Founder left for his eternal reward, he established five religious houses: Dehra Dun, Tajpur, Sardhana, Siwal, and Saharanpur. In the beginning the young Sisters did not have the needed training, yet they began to work in the schools, hospitals, dispensaries and actively involved themselves in the pastoral work of the parishes. Of course, these years were a period of struggle for the Sisters. But the Founder as well as the Priests of the Meerut Diocese was of great support. Sister Lucy, MSA and her assistants too worked hard to form the young sisters spiritually as well as to take care of their material needs.</p>
                                <p>As soon as the first batch of Sisters was professed, the Founder sent them for professional studies and thus equipped them to take care of various mission activities. Though they lacked experience, they worked hard getting guidance from experienced persons. From <b> 1969</b> onwards, the young Sisters began to shoulder the responsibilities of being Superiors, and of taking charge of hostels and dispensaries. Since Archbishop Evangelisti had now retired, a question arose as to who was in charge of the Congregation. The matter went to the Apostolic Nuncio who informed that being the Founder of the Congregation he could advise the Sisters. But he could not issue directives since the Diocesan Administrator was now the head of the Congregation. So, Rev. Fr. John Chamakala, the Administrator of the Diocese appointed Sister Gemma as Superior General of the Congregation just after her final profession, in place of Mother Angela<b> on May 30, 1974. </b> Sister Lucy, MSA went back to her Congregation<b> in 1974.</b> Trusting in Divine Providence and the protection of Our Lady of Graces, the young Congregation marched ahead braving the trials and difficulties of everyday life. </p>
                      </div>
                   </div>
                
                   </div>
               </div>
           </div>
                
       <Footer />
       </>
   )
}
export default History
import {Link} from 'react-router-dom'
const Breadcrumb = (props) => {
    return(
        <>
        <div className="breadcrumb">
            <div className="breadcrumb-blk">
                <h2>{props.pageTitle}</h2>
                <ul>
                    <li><Link to="/">Home</Link></li> 
                    <li>|</li>
                    <li>{props.mid}</li> 
                    <li>|</li>
                    <li>{props.breadcrumbName}</li>
                </ul>
            </div>
        </div>
        
        </>
    )
}
export default Breadcrumb
import { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";


const EducationMinistry = () => {
    const [selectedTab, setSelectedTab] = useState("smcsGhaziabad");

    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Education Ministry" mid="Apostolates" breadcrumbName="Education Ministry" />
            <div className="innerpage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="title">Our Institutions</h2>
                        </div>
                        <div className="col-md-4">
                            <div className="tab_select">
                                <div 
                                    onClick={() => handleTabClick("smcsGhaziabad")}
                                    className={selectedTab === "smcsGhaziabad" ? "tab-item active" : "tab-item"}
                                >
                                    SMCS Ghaziabad
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcs")}
                                    className={selectedTab === "smcs" ? "tab-item active" : "tab-item"}
                                >
                                    St. Mary's Convent School
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsLucknow")}
                                    className={selectedTab === "smcsLucknow" ? "tab-item active" : "tab-item"}
                                >
                                    Stella Maris Inter College, Sector - H, L.D.A Colony, Lucknow.
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsNoorpur")}
                                    className={selectedTab === "smcsNoorpur" ? "tab-item active" : "tab-item"}
                                >
                                    St. Mary's Inter College, Noorpur
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsAgra")}
                                    className={selectedTab === "smcsAgra" ? "tab-item active" : "tab-item"}
                                >
                                    Stella Maris Inter College, Agra
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsMeerut")}
                                    className={selectedTab === "smcsMeerut" ? "tab-item active" : "tab-item"}
                                >
                                    St. Francis Convent School, Kankerkhera, Meerut
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsVikasnagar")}
                                    className={selectedTab === "smcsVikasnagar" ? "tab-item active" : "tab-item"}
                                >
                                    St. Marys Convent School, Vikasnagar, Dehradun
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsDakPathar")}
                                    className={selectedTab === "smcsDakPathar" ? "tab-item active" : "tab-item"}
                                >
                                    St. Mari's Convent School, Dak Pathar
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsShimla")}
                                    className={selectedTab === "smcsShimla" ? "tab-item active" : "tab-item"}
                                >
                                    St. Mary's Convent School, Dausa
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsGulaothi")}
                                    className={selectedTab === "smcsGulaothi" ? "tab-item active" : "tab-item"}
                                >
                                    St. Mary's Convent School Gulaothi
                                </div>
                                <div 
                                    onClick={() => handleTabClick("smcsBhartiagram")}
                                    className={selectedTab === "smcsBhartiagram" ? "tab-item active" : "tab-item"}
                                >
                                    St. Mary's Convent School, P.O. Bhartiagram
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="institute">
                                {selectedTab === "smcsGhaziabad" && (
                                    <>
                                               <h3>SMCS Ghaziabad</h3>
                                <div className="flex-v three row"> 
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst1-1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst1-1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst1-2.jpg" >
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst1-2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Activity</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst1-3.jpg" >
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst1-3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Assembly</h4>
                                        </a>
                                    </div> 
                                    </div>
                                    </>
                                )}
                                {selectedTab === "smcs" && (
                                    <>
                                       <h3> St. Mary's Convent School</h3>
                                <div className="detail-box">
                                    <div>
                                        <p><strong>Name:</strong>  St. Mary's Convent School<br />
                                            <strong>Place:</strong>  H. Malligere Village and Post, Dudda Hobli,
                                            Mandya Taluk and Dist. Pin Code - 571 402<br />
                                            <strong>Established:</strong> 1st June 2013<br />
                                            <strong>Board:</strong> CISCE, (Council for the Indian School Certificate Examinations) New Delhi</p>
                                    </div>
                                    <div>
                                        <p><strong>Affiliation Code:</strong> KA354<br />
                                            <strong>Provisional affiliation :</strong> 07th May 2018<br />
                                            <strong>Permanent Affiliation:</strong> 12th May 2023<br />
                                            <strong>ISC affiliation:</strong> 01st February 2022</p>
                                    </div>
                                </div>
                                
                                <div className="tab-content">
                                <div className="flex-v row">
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-3.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Assembly</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Assembly</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-4.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/fslg-Inst2-4.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Assembly</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsLucknow" && (
                                    <>
                                        <div className="tab-content">

<h3> Stella Maris Inter College, Sector - H, L.D.A Colony, Lucknow.</h3>
<div className="flex-v three row"> 
<div className="flex-img col-lg-4 col-md-6">
        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella1.jpg">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
            <h4>School Building</h4>
        </a>
    </div>
    <div className="flex-img col-lg-4 col-md-6">
        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella2.jpg">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
            <h4>Activity</h4>
        </a>
    </div>
    <div className="flex-img col-lg-4 col-md-6">
        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella3.jpg">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
            <h4>Assembly</h4>
        </a>
    </div>
</div>
</div>
                                    </>
                                )}
                                {selectedTab === "smcsNoorpur" && (
                                    <>
                                       <div className="tab-content">
                                <h3>St. Mary's Inter College, Noorpur</h3>
                                <div className="flex-v three row"> 
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/noorpur3.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/noorpur3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/noorpur1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/noorpur1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Sports Activity</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/noorpur2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/noorpur2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Assembly</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsAgra" && (
                                    <>
                                       <div className="tab-content">
                                <h3>Stella Maris Inter College, Agra</h3>
                                <div className="flex-v three row"> 
                                <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella-agra1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella-agra1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella-agra2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella-agra2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Diwali Activity</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella-agra3.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/stella-agra3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Morning Assembly</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsMeerut" && (
                                    <>
                                           <div className="tab-content">
                                <h3>St. Francis Convent School, Kankerkhera, Meerut</h3>
                                <div className="flex-v row">
                                <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Cultural Activity</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut3.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Morning Assembly</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut4.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sfcsmerrut4.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Sports Activity</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsVikasnagar" && (
                                    <>
                                       <div className="tab-content">
                                <h3>St. Marys Convent School, Vikasnagar, Dehradun</h3>
                                <div className="flex-v two row">
                                <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/vikasnagar2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/vikasnagar2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/vikasnagar1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/vikasnagar1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Sport Activity</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsDakPathar" && (
                                    <>
                                         <div className="tab-content">
                                <h3>St. Mari's Convent School, Dak Pathar</h3>
                                <div className="flex-v two row">
                                <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dak-pathar2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dak-pathar2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Morning Assembly</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dak-pathar1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dak-pathar1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Morning Assembly</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsShimla" && (
                                    <>
                                     <div className="tab-content">
                                <h3>St. Mary's Convent School, Dausa</h3>
                                <div className="flex-v two row">
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dausa1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dausa1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dausa2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smc-dausa2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Morning Assembly</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsGulaothi" && (
                                    <>
                                      <div className="tab-content">
                                <h3>St. Mary's Convent School Gulaothi</h3>
                                <div className="flex-v row">
                                <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi3.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Activity</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi4.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi4.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Morning Assembly</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgulaothi2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                                {selectedTab === "smcsBhartiagram" && (
                                    <>
                                          <div className="tab-content">
                                <h3>St. Mary's Convent School, P.o. Bhartiagram, Gajraula, Distt. Amroha Uttar Pradesh - 244223</h3>
                                <div className="flex-v row">
                                <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula1.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula2.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula3.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula4.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula4.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula5.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula5.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>School Building</h4>
                                        </a>
                                    </div>
                                    <div className="flex-img col-lg-4 col-md-6">
                                        <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula6.jpg">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/smcgajraula6.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid galleryimg" />
                                            <h4>Morning Assembly</h4>
                                        </a>
                                    </div>
                                </div>
                                </div>
                                    </>
                                )}
                            </div> 
                            </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
);
                                }
export default EducationMinistry;

import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"

const Administration = () => {
   return( 
       <>
       <Header />
       <Breadcrumb pageTitle="Administration" mid="About" breadcrumbName="Administration" />
       <div class="innerpage">
           <div className="container">
           <div class="administration-blk">
           <h2 className="title">2021-2026</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Sindu.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Sindu</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Rajni.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Rajni</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/sr-navya.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Navya</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Reni</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Linet</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Reema</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">2015-2021</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Mercy</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Jessy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr.Jessy</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Pushpa</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Katheline</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Reni</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Sindu.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Sindu</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">2009-2015</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Rajani</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Pushpa</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Jessy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Jessy</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Jane.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Jane</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Leena</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Sindu.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Sindu</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">2003-2009</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Mercy</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/SR-LUCIA.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Lucia</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Gemma.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Gemma</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Lily.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Lily</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Cecilia.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Cecilia</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Rajani</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">1997-2003</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr. Matilda.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Matilda</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Mercy</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Gemma.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Gemma</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Jane.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Jane</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Liza.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Liza</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/SR-LILLIAN.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Lillian</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">1991-1997</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr. Matilda.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Matilda</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Josephina</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Gemma.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Gemma</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Jaya</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Pushpa</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Lillian</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">1985-1991</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Jane.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Jane</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Kathleen</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Teresa</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Gemma.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Gemma</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Cecilia.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Cecilia</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Vincy</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">1979-1985</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Gemma.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Gemma</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Elizabeth</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr. Matilda.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Matilda</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Aurelia</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/SR-MARY.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Mary</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Regis</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">1975-1979</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Gemma.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Gemma</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Teresa</h3>
                                     <p>General Councilors, Secretary General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Alphonsa</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Catherine</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr. Matilda.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Matilda</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Mary</h3>
                                     <p>General Bursar</p>
                                     </div>
                                 </div>
                             </div>
                             <h2 className="title">1974-1975</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr-Gemma.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Gemma (Appointed)</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Sr. Matilda.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Matilda</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Aurelia</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Germaine</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div>
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Sr. Teresa</h3>
                                     <p>General Councilors</p>
                                     </div>
                                 </div> 
                             </div> 
                             <h2 className="title">1965 -1974</h2>
                             <div class="flowerbox row">
                                 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="administration_blk">
                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/dummy.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" />
                                     <h3>Bishop/Mother Angela</h3>
                                     <p>Superior General</p>
                                     </div>
                                 </div> 
                             </div>
                         </div>
           </div>
               
           </div>
       <Footer />
       </>
   )
}
export default Administration
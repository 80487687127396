import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"

const ShantiDhara = () => {
   return( 
       <>
       <Header />
       <Breadcrumb pageTitle="Shanti Dhara" mid="Coummunities" breadcrumbName="Shanti Dhara" />
       <div className="innerpage">
           <div className="container">
               <div className="row">
                   <div className="col-md-12"> 
                    <h2 className="title">Ghaziabad Region  </h2>
                     <h3 className="sub_title">Shanti Dhara </h3>
                     <div className="row">
                        <div className="col-md-3">
                        <div class="area-pic"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/shanti-dhara-img.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" /></div>
                        </div>
                        <div className="col-md-9">
                        <p><b>List of Communities</b></p>
                     <ul className="communities">
                         <li> Bad kreuznach </li>
                        <li> Dalelpura, Dausa </li>
                        <li> Dasna </li>
                        <li> Daurala </li>
                        <li> Dausa </li>
                        <li> Gajraula </li>
                        <li> Gajraula (Sneh Sadan) </li>
                        <li> Gandhupal </li>
                        <li> Ghaziabad </li>
                        <li> Jwalapur </li>
                        <li> Kashipur </li>
                        <li> Kazhikhera </li>
                        <li> Khammam </li>
                        <li> Modinagar </li>
                        <li> Mudikkanam </li>
                        <li> Mushalpur </li>
                        <li> Pakur </li>
                        <li>Palayur </li>
                        <li> Raikot </li>
                        <li> S. Madhopur </li>
                        <li> Saket </li>
                        <li> Sardhana </li>
                        <li> Siwal </li>
                        <li> Tajpur </li>
                        <li> Talcher </li>
                        <li> Vernante </li>        
                     </ul>
                        </div>
                     </div>
                     
                      
                 </div>
                 <div className="flex-v row"> 
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara1.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara1.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Our Lady of Graces Convent, Mushalpur, Assam</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara2.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara2.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Kripa Sadan Convent, Talcher</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara3.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara3.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St Francis Convent Palayur</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara4.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara4.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St. Mary_s Convent, Gandhupal</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara5.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara5.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Our Lady of Graces Convent, Modinagar</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara6.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara6.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Convento San Giuseppe. Vernante, Italy_</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara7.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara7.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Our Lady Of Graces Convent Sawaimathopur</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara8.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara8.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St. Mary's Convent Shastri Nagar, Ghaziabad</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara9.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara9.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Norbert House</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara10.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara10.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Sneh Sadan, Gajraula</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara11.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara11.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Franciscan Sisters of Our Lady of Graces Convent, Badkreuznach Germany</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara12.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara12.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Jyoti Niwas, Khammam</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara13.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara13.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St.Mary's Convent,  Dalelpura</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara14.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara14.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Our Lady of Graces Convent,  Siwal</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara15.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara15.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Our lady of Graces Convent Raikot</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara16.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara16.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Maria Sadhan, Daurala</h4>
                            </a>
                        </div>  
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara17.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara17.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Maria Assumpta Convent, Kashipur</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara18.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara18.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St.Agnes Convent,  Khazikheda</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara19.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara19.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St.Mary's Convent Gajraula</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara20.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara20.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St. Mary's Convent, Dausa</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara21.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara21.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Our Lady of Graces  Convent Saket</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara22.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara22.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>Our Lady of Grace's Convent, Pakur</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara23.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara23.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St. Marys Convent Mudikkanam</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara24.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara24.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St. Mary's convent Sardhana</h4>
                            </a>
                        </div>
                        <div className="flex-img col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara25.jpg">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/Shantidhara25.jpg" alt="Franciscan Sisters of Our Lady Of Graces" class="img-fluid galleryimg" />
                                <h4>St. Mary's convent, Dasna</h4>
                            </a>
                        </div> 
                     </div>
                   </div>
               </div> 
               
           </div>
       <Footer />
       </>
   )
}
export default ShantiDhara
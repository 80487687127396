import { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';

const News = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getNewsInner();
        setData(galleryData);

        const yearsSet = new Set();
        const monthsSet = new Set();

        galleryData.forEach(item => {
          if (item.fromDate) {
            const date = new Date(item.fromDate);
            yearsSet.add(date.getFullYear());
            monthsSet.add(date.getMonth() + 1); // Months are zero-based
          }
        });

        const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b - a);
        setYearRanges(uniqueYearRanges);

        const uniqueMonths = Array.from(monthsSet).sort((a, b) => a - b);
        setMonthOptions(["All", ...uniqueMonths.map(month => month.toString())]);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterDataByYearAndMonth = (year, month) => {
      return data.filter(item => {
        if (item.fromDate) {
          const date = new Date(item.fromDate);
          const itemYear = date.getFullYear();
          const itemMonth = date.getMonth() + 1;
          return (year === "All" || itemYear === parseInt(year)) &&
                 (month === "All" || itemMonth === parseInt(month));
        }
        return false;
      });
    };

    const filterAndSetData = () => {
      let filteredData = filterDataByYearAndMonth(selectedYear, selectedMonth);

      if (filteredData.length === 0 && selectedYear !== "All") {
        const previousYear = yearRanges.find(year => year < parseInt(selectedYear));
        if (previousYear) {
          filteredData = filterDataByYearAndMonth(previousYear.toString(), selectedMonth);
          if (filteredData.length > 0) {
            setSelectedYear(previousYear.toString());
          }
        }
      }

      if (searchQuery) {
        filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
      }

      setFilteredData(filteredData);
    };

    filterAndSetData();
  }, [selectedYear, selectedMonth, searchQuery, data, yearRanges]);

  return (
    <>
      <Header />
      <Breadcrumb title="Latest News" pageName="Latest News" mid="News" />
      <div className="innerpage">
        <div className="container">
          <div className="row">
            <div className='col-md-12'>
              <h2 className='title'>Latest News</h2>
            </div>
          </div>
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="count-val">
                <p>Total Count: {filterData.length}</p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select  
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="monthSelect"> 
                  {monthOptions.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row years year2023 showYear">
            {filterData.length > 0 ? (
              filterData.map((item, index) => (
                <div className="col-md-12 newsCount" key={index}>
                  <div className="inr-news-blk">
                    <h6 className="date">{item.date}</h6>
                    <div className="inr-news-content">
                      <h3 className="sub_title">{item.title}</h3>
                      <p>{item.description}</p>
                      <h6 className="loc">
                        {item.attachments && item.attachments.length > 0 && (
                          <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank">
                            <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                          </Link>
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <div className="inr-news-blk">
                  <h6 className="date">DD.MM.YYYY</h6>
                  <div className="inr-news-content">
                    <h3 className="sub_title">News Title</h3>
                    <p>Stay Tuned for more Updates</p>                    
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;

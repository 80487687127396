 import Header from "../components/Header"
 import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"

const Founder = () => {
    return( 
        <>
        <Header />
        <Breadcrumb pageTitle="Founder" mid="About" breadcrumbName="Founder" />
        <div class="innerpage">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <h2 className="title"> Archbishop Joseph Bartholomew Evangelisti OFM Cap  </h2>
                       <div class="founderbox">
                            <div class="founderboximg"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/FSOLG/public/Images/founderimg.jpg" alt="Franciscan Sisters of Our Lady Of Graces" className="img-fluid" /></div>
                           <p>Archbishop Joseph Bartholomew was born on April 8, 1908 at Casa Calistri in a village known as Granaglione to Sebastiano and Assunta Evangelisti.  They christened him Joseph at baptism.  He was the seventh of the eight children – five girls and three boys. He lost his beloved mother when he was seven years old.  His father used to take him for long walks and on one such occasion they met two Capuchin Friars.  Pointing to them his father asked him whether he would like to be one of them. Promptly he answered ‘yes’ and true to his word he became one.</p>
                                <p>At an early age of thirteen, Joseph left his family to join the Seraphic Seminary.  He was vested in the Capuchin habit on September 21, 1923 taking the name Bartholomew.  After finishing his Philosophical studies, he went to the Gregorian University in Rome to do his Theology.  His young heart was overwhelmed with gratitude on July 17, 1932 when he was ordained a Priest.  Having obtained the Licentiate “cum laude” (80%) in Dogmatic Theology, he taught at the Capuchin Theological Faculty in Florence and other places.</p>
                                <p>The Capuchins by then had many missions in India.  And an attraction for this far away land was slowly developing in the mind of Fr. Bartholomew.  He longed to solace the afflicted brethren in India – to feed a hungry mouth, to brighten the mind of an illiterate, to bind up the wounds of a leper…….. .. It was mentioned in his spiritual diary that he had a vision of Our Lord during prayer.  Jesus told him that he would have to suffer greatly, if he went to India.  He opted for India and the promised reward of ‘suffering’ he had in plenty.  But his prayer was always for the grace needed to suffer patiently till the end.  </p>
                                <p>In 1936, Fr. Bartholomew left Florence for the Capuchin Mission of Agra and was sent to Barlowganj, Mussoorie, to teach Theology after which he worked in the Parishes of Dehra Dun and Moradabad. Hard days were ahead for him.  During the Second World War, he was put in a Concentration Camp for one and a half years in Dehra Dun by the British along with other Italian Bishops and Priests. In the spring of 1944, he was released and was appointed the Manager of St. Charles’ Boarding at Sardhana till 1952.  He worked hard to feed and clothe the boys under his care.  His boarders remember him as a righteous priest who meted out justice impartially.</p>
                                <p>On July 10, 1952, Fr. Bartholomew was nominated Titular Archbishop of Brisi and Coadjutor to the Archbishop of Agra.  On February 20, 1956, the vast Archdiocese of Agra was divided and Archbishop J.B. Evangelisti was made the first Bishop of the new Diocese of Meerut on February 29 of the same year.  But he continued to retain his personal title of Archbishop. </p>
                                <p>As a Bishop filled with exceptional apostolic zeal, Evangelisti put great emphasis on evangelical work.  He brought Sisters from various Religious Congregations to improve the catechetical, educational and medical work of the Diocese.  He visited his mission stations often and on such occasions holding the Crucifix in his hand, he would speak to the people about God for hours together.  He realized, “To make the message of Christ known, it was not enough to have a missionary or two in the main cities, but fervent apostles, priests and religious, in every hamlet and village of North India.” He also understood that the realization of such a plan was not possible with the Sisters then available in the apostolic field. “It was then that we started thinking about founding a Diocesan Congregation that might give us an army of brave apostolic Sisters to help us in our ecclesiastical unit, and elsewhere if it pleased God.”  It was at this time Bishop Angelo Poli, OFM Cap. (1878 – 1970), the retired Bishop of Allahabad, who came to know about the Archbishop’s plan of founding a Congregation, requested him to accept into his new Congregation, seven Franciscan Tertiaries who were members of a Community which was under his care. </p>
                                <p>Meanwhile, being a great devotee of Mother Mary, the Archbishop had so much trust and confidence in her powerful protection and guidance.  Therefore, he wanted to open a Shrine in honour of Our Lady of Graces at Sardhana where there was a big Church in the name of Our Lady of Assumption which was not well known.  So, when he went to Italy in 1956, he got the picture of Our Lady of Graces blessed by both Pope Pius XII and St. Padre Pio.  On his return on November 7, 1957, he installed the picture solemnly in the Church at Sardhana which was declared a minor Basilica by Pope John XXIII at his request on December 19, 1961. People from India and abroad have been going there to seek many graces through our heavenly Mother. 
                                </p><p>Bishop A. Poli repeated his request a second time.  Thus, it was clear that God wanted Archbishop Evangelisti to begin the new Congregation which he had in mind.  Thus, after much prayer and reflection, he accepted the seven Franciscan Tertiary Sisters of Allahabad in 1962.  He founded the Congregation of the Franciscan Sisters of Our Lady of Graces on June 25, 1965 and got the canonical deficiency of those seven Sisters ratified through the Prefect of Propaganda Fide on June 29 of the same year.  Ten candidates from Kerala were also accepted by the Congregation on July 24, 1965.  The Archbishop got two Missionary Sisters of Ajmer, Sisters Lucy and Maris to train the new candidates.</p>
                                <p>Archbishop Evangelisti, as a person, was an embodiment of many qualities and principles. First and foremost, he was a man of prayer.  He was always united with God. In all that he did, he sought the guidance of the Holy Spirit. Throughout his life the inspiration and guidance of God was seen.  He was faithful to his meditation, prayer and Holy Mass.  Being a man of deep faith and prayer never was he known to postpone or leave out his hour with the Lord.  From the very beginning, he wanted his daughters to love God and trust in Divine Providence.  He guided and directed them to strengthen their spiritual life through his talks and letters even during his illness.  He stressed that holiness and prayer life of the Sisters are the secret of the growth and strength of the Congregation.</p>
                                <p>As said earlier, the Archbishop had a filial devotion to our Blessed Mother.  His deep faith and trust in her was reflected in the prayer he composed in honor of Our Lady of Graces.  Because of this great love and devotion to her, he entrusted the Diocese to her patronage.   It was to her maternal care and protection that he entrusted his spiritual daughters of the newly founded Congregation which was named as the Franciscan Sisters of Our Lady of Graces.</p>
                                <p>In every sense, Bishop Evangelisti was a true Franciscan, who tried to lead a life of detachment and poverty.  As a Bishop, he had to deal with money.  But trusting in the providence of God, he spent all the money that came to him for the good of the Diocese and for the welfare of the poor.  He lived, as the Gospel says, “in the world but he did not belong to the world.”  He made use of not only money but also his talents and abilities for the spiritual and temporal growth of others.</p>
                                <p>As a true follower of St. Francis, he had a special love for the leprosy patients. In 1962, he established Kripaon-Ki-Matha Leper Colony in Dehra Dun to take care of these outcasts of the society.  He also opened a boarding and a school at Raja-Ka-Tajpur for the children of leprosy patients so as to save them from the clutches of this sickness as well as to give them a place in the society.</p>           
                                <p>The Archbishop was a true son of the Mother Church.  He stressed that as members of the Mystical Body of Christ, love for the Mother Church is an essential part of every Christian. He told his spiritual daughters that the growth of the Congregation is the growth of the Church too.  He exhorted them to cooperate with Christ and the Church for the salvation of humankind.</p>
                                <p>As a caring Pastor, he was very much concerned about the spiritual and temporal needs of his flock.  He made his pastoral visitation not only to the Parishes but also to every mission station.  He spent two to three days in each place, checked all records, accounts, met the Priests, Religious and Laity, listened to their problems and difficulties and helped them out in every possible way.  Though it had been very difficult to reach some of the mission stations due to lack of proper roads, he carried a spade in his vehicle to level the ground and to fill the ditches to make a way through the fields. He gladly and courageously faced such adventurous situations.</p>
                                <p>Though he was the spiritual head of the Diocese, he was interested in the all around development of his people.  He foresaw the role; education could play in this regard.  So he opened Hindi medium Primary as well as Middle Schools in many of the village Parishes.  He also established an ‘Educational Fund’ to facilitate the education of poor children as well as their training.  Those with financial difficulties could take a loan from this fund for education, free of interest, and could return the amount when they got a job. </p>
                                <p>The Archbishop always had a burning desire to spread the Good News of love, peace and joy of God’s Kingdom on earth.  He used to say, “I have only two hands and feet and if I had more hands and feet the good message could have been extended more effectively in the world.”  When he founded the Congregation, he earnestly wished that his spiritual daughters would spread the Good News of the Kingdom first of all in Meerut Diocese and then to various parts of India as well as abroad.  Evangelization was his greatest concern and he spared no effort to make Christ known and loved by everyone.  He preached Christ like St. Paul ‘in season and out of season.’  In order to preach to the people, he made himself very fluent in Hindustani, Hindi and English.  Whenever he went to any mission station, due to his strange appearance and strange dress, children used to crowd around him.  Thinking that the children have come to listen to him, immediately he would begin to speak to them about God, Jesus and how God loves them. The words of St. Paul, “Woe to me if I don’t preach the Gospel” is applicable to Archbishop Evangelisti.   Nothing could stop him from preaching the Good News.  Even when he was sick and bed-ridden he made use of every opportunity that came to him to give a spiritual message.  During his visit to Lourdes lying on a stretcher, he preached to the people about the love of God, about the Mother of God and her love for all her children.  Since Evangelization was his main thrust, he guided and inspired his spiritual daughters by his talks and writings to inculcate in them great missionary zeal.</p>
                                <p>In keeping with the Franciscan Spirit, the Archbishop worked for peace and harmony among all specially among various Christian denominations.  He saw Christian unity as another means of evangelization.  So he used to attend the Ecumenical meetings in Meerut and to give very inspiring and enriching talks through which he appealed to all Christians to maintain unity and understanding among them so as to spread the message of Christ to the non-Christian brethren. He also grasped the importance of dialogue to keep up religious harmony.  He saw it as an opportunity to know and understand the other religious teachings while making Christ and His teachings known to the non-Christians. Thus, he gave great importance and encouragement to Ecumenical meetings and dialogue.      </p>
                                <p>Though he was the Bishop of the Diocese, as a true Franciscan he used to spend one or two hours in the morning and one hour in the evening working along with the gardener in the vegetable and flower garden as well as in the poultry whenever he could.   He instructed the gardener about what, where and when to plant both in the flower garden as well as in the vegetable garden.  Except for some gatherings, no meat, no vegetables were bought in the Bishop’s House. There were always plenty of beautiful flowers in the garden.  Like St. Francis of Assisi he had a special love for animals and birds and everyday he spent some time with them.  </p>
                                <p>The Archbishop was known for his punctuality. Very meticulously he followed his well set time table in all his activities. His day began at 5.30 a.m. and ended at 11.00 p.m. He was also very particular about observing the Liturgical rules and regulations.   He loved his Priests, he was good and kind to them, yet he was quite strict and firm. He used to observe them while they offered the Holy Mass and pointed out any deviation they might have made in liturgical worship. </p>
                                <p>Archbishop Evangelisti’s life style was very simple. He ate simple food but with regularity.  He enjoyed simple jokes even when it was at his own expense.  He was a contented and satisfied person.  Like other Italian Missionaries, he was also very self-sacrificing.  Due to his spirit of poverty, he never wasted anything. He wanted his daughters also to lead a humble, simple and poor life. He always insisted on being generous in sharing what they have with the poor, the needy and the sick. He exhorted them to have a special love for St. Francis of Assisi and to follow him in his spirit of poverty and detachment from persons and things of this world. Archbishop Evangelisti became an Indian citizen to be one with the people whom he came to serve. This was a proof of his detachment to his own country and his own kindred, and his love for the poor whom he served. </p>
                                <p>On April 17, 1965 the Archbishop suffered a mild heart attack.  He was admitted to Holy Family Hospital, Delhi, for a month. There they diagnosed that he had peptic ulcer too. With medicine, diet and rest he recovered. On the morning of July 5, 1971 when he was in Barlowganj, Mussoorie for a pastoral visitation he experienced a shooting pain on his back and was unable to get out of his bed.  He was rushed to Holy Family Hospital, Delhi where the investigations showed that he had multiple myeloma (Cancer of the bone marrow).  After a second biopsy he had “foot drop” of the left leg which forced him to remain in bed.  For cobalt treatment he was taken to Wellington Hospital, Delhi.  Due to over exposure to cobalt rays, his back was burnt and a slough was formed.  His condition worsened and the doctors gave him a week to live.  He was brought back, and with proper diet and care his condition improved.</p>
                                <p>Though he was sick and in bed, he never rested. It was during his illness that he wrote with great love the Constitutions and Directory of his newly founded Congregation so as to enable his spiritual daughters to achieve a high degree of sanctity in their spiritual and religious life. Being bed-ridden, it was not an easy job.  But he did this either by lying on his stomach or on his side.  When he completed this tedious job he thanked God saying, “If I had not been sick, I would not have been able to complete it.” He also sent a good number of circulars to guide the Sisters, prayed 15 decades of the Rosary every day, read books, received all those who came to meet him and spoke about God so cheerfully that he even forgot about his pain and sickness. He visited Raja-Ka-Tajpur and stayed a few days during which he advised and exhorted the young Sisters, Novices, Postulants and Aspirants to be faithful to Jesus.</p>
                                <p>Compelled by ill health, Archbishop Evangelisti resigned from his office as Bishop of Meerut on August 3, 1973. Further check up and treatment caused him much pain instead of relief.  On and off his health seemed to be improving. At one stage, the doctors in the Military Hospital declared that he had no such sickness as Myeloma. Later, he was taken to Rome. Investigations in Clinica Ars Medica on May 6, 1974 showed that his disease was spreading and he had holes in the skull. Chemotherapy was given and later he was shifted to the Capuchin Fathers’ Infirmary at Florence.  When he was in Florence, he made a pilgrimage to Lourdes to be at the feet of the Blessed Mother and to offer his suffering to God through her. He returned happy, peaceful and enriched by the grace to carry his cross courageously.    </p>
                                <p>The Archbishop’s attitude towards his fatal sickness and suffering was something to be noted. He never complained. He always looked happy and contented. One day, Most Rev. Angelo Fernandez, Archbishop of Delhi, came to visit him. He chatted with the patient for half an hour. When he came out of the room he said, “I came to console and comfort your Bishop. But he gave me consolation.” When Archbishop Evangelisti was taken to the shrine of Our Lady of Graces in Sardhana sitting on his wheel chair he prayed, “Mother I have not come here to ask you to work a miracle and cure me. But I pray that you give me the grace to do the will of God by bearing my suffering to the end.” Even when he made his pilgrimage to Lourdes in 1974, lying on the stretcher he concelebrated Holy Mass with Bishops, carried the monstrance on his chest during the procession, heard confessions, preached to the people about Mother of God and her love for her children and specially prayed for his successor as well as for his spiritual daughters. Here too, he did not pray so much for his healing but he offered his suffering to God through the Blessed Virgin Mary.</p>

                                <p>Since Archbishop Evangelisti was very much united with God, he was able to see the hand of God in everything. It was with tremendous faith he accepted his pains and aches. His strong faith in God and his understanding of the value of suffering gave him the strength to carry his heavy cross uniting it to the sufferings of Christ on the cross. This firm faith helped him to see a lesson of love from the Lord, and in May, 1973 he wrote, “It has helped me well, it has taught me to suffer patiently, to conform my will to the will of God, to prepare me for death and take me to paradise.” In one of his last letters to his Father Provincial on March 28, 1976, he mentioned, “I am continuing my way of the cross and I don’t know to which station I have arrived. I don’t desire the stations to be short, because they have been long enough. Again I leave everything in the hands of God.”  </p>

                                <p>The suffering he underwent from 1971 to 1976 was the fulfillment of the vision he had as a young Priest.  For five long years he remained in bed unable to turn from one side to the other. Even a slight movement caused him unbearable pain. During the last few months he was afflicted with boils and fever. Gradually he began to lose his eye sight. Being a man of God, he was able to see God’s plan in everything – in joys and sorrows, in success and failures, in good health and suffering. </p>

                                <p>On March 17, 1976 he was administered the Sacrament of the Sick. His condition further deteriorated.  His bones became brittle and nine of his ribs were fractured and had holes in all the bones. He was aware that his end was approaching.  He made sure that everything was ready for his burial. In spite of the terrible pain he looked fresh, cheerful and peaceful. From Wednesday of the Holy Week, onwards he was not able to offer Holy Mass. But he received Holy Communion. On Easter Day he told those who visited him that he was going to heaven for Easter. The day was peaceful. At night at 11 p.m. he spoke a few words, “We are useless here.” On April 19, 1976 Easter Monday morning at 8.45 while Rev. Fr. Mathew Edathattel was blessing him, the Archbishop breathed his last without any struggle. This great missionary had said earlier:  “Having spent 36 years in India, the most beautiful years of my life, I do not intend to go back to Italy, but to end my earthly pilgrimage in this land which I have bathed with my sweat in various places of my apostolate and to rest my bones close to the shrine of Our Lady of Graces at Sardhana, which the Lord gave me the grace to open in 1957.” This wish of his was fulfilled and today his mortal remains rest near to the shrine of the Blessed Mother whom he loved greatly while he lived. 
                                </p><p>Archbishop Evangelisti carried his daily cross cheerfully till the end. Undoubtedly he suffered and offered his suffering to the Lord for the Diocese of Meerut and in a special way for his spiritual daughters so that they spread the Good News to everyone. Today, the Founder Father of the Franciscan Sisters of Our Lady of Graces through his invisible presence continues to inspire and invite many to follow his heroic path of love and service. </p>
                        </div>
                    </div>
                </div>
            </div>
                
            </div>
        <Footer />
        </>
    )
}
export default Founder